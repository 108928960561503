import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
//import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import { StaticImage } from "gatsby-plugin-image"

import Header from "../components/headers/header"
import Ariane from "../components/ariane"
import Footer from "../components/footers/footer"
//import Sidebar from "../components/sidebars/sidebarGeo"


import CTAOpengroupe from "../components/shortcodes/CTAOpengroupe"
import CTAExpertPresChezVous from "../components/shortcodes/CTAExpertPresChezVous"
import CTAExpertAvisIndependant from "../components/shortcodes/CTAExpertAvisIndependant"
import Youtube from "../components/shortcodes/youtube"

import Formulaire from "../components/shortcodes/Formulaire"
import CTAHilaireAvocatsGenerique from "../components/shortcodes/CTAHilaireAvocatsGenerique"
import CTAKitFissuration from "../components/shortcodes/CTAKitFissuration"

const shortcodes = {
  Youtube,
  StaticImage,
  CTAOpengroupe,
  CTAExpertPresChezVous,
  CTAExpertAvisIndependant,
  CTAHilaireAvocatsGenerique,
  Formulaire,
  CTAKitFissuration
}


const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane
      },
      body
    }
  }
}) => {
  return (
    <div className="bg-white">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white">
        <Header />
      </header>
      <div class="relative max-w-7xl mx-auto shadow-lg lg:shadow-none lg:flex mb-4">
        <Ariane fil_ariane={fil_ariane} />
      </div>

      <div className="bg-white px-6 py-16 lg:px-8">
        <main className="mx-auto max-w-7xl text-base leading-7 text-gray-700">

          <div className="text-lg max-w-none mx-auto">
            <h1 className="mt-2 mb-2 block text-4xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl prose-a:kdp">
              {title}
            </h1>
          </div>

          <div className="mt-6 prose-h2:border-b prose-h2:border-gray-200 max-w-none prose prose-kdp prose-lg mx-auto prose-img:rounded-xl prose-img:shadow-lg prose-img:float-right prose-img:ml-4">
            <MDXProvider components={shortcodes}>
              <MDXRenderer
                className="mt-6 prose prose-sky prose-lg text-gray-500 mx-auto"
                remoteImages={embeddedImagesRemote}
                localImages={embeddedImagesLocal}
              >
                {body}
              </MDXRenderer>
            </MDXProvider>
          </div>


        </main>




      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};
export const query = graphql`
  query($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;

